<template>
    <div class="offline-cassette">
        <el-breadcrumb class="crumb" separator="/">
            <span @click="goBack" class="go-back-icon"><i class="el-icon-arrow-left"/></span>
            <el-breadcrumb-item :to="{ path: '/offline_cassette' }" class="bucket-crumb">离线盘匣管理</el-breadcrumb-item>
            <el-breadcrumb-item > 任务列表 </el-breadcrumb-item>
        </el-breadcrumb>
        <div class="cassette-page">
            <div class="cassette-opts">
                <div>
                </div>
                <div style="display: flex;">
                    <el-select
                        v-model="lineStatus"
                        clearable
                        placeholder="选择任务类型"
                        size="200px"
                        @change="taskStatusChange"
                    >
                        <el-option
                            v-for="item in taskTypeList"
                            :value="item.value"
                            :label="item.label"
                            :key="item.value"/>
                    </el-select>
                    <SpaceItem></SpaceItem>
                    <el-select
                        v-model="actionStatus"
                        clearable
                        placeholder="选择任务状态"
                        style="flex-basis: 140px;flex-shrink: 0;flex-grow: 0"
                        @change="taskStatusChange"
                    >
                        <el-option
                            v-for="item in taskStatusList"
                            :value="item.value"
                            :label="item.label"
                            :key="item.value"/>
                    </el-select>
                </div>
            </div>
            <div  class="table-container" >
                <el-table ref="listTable" v-loading="loadingData" :data="tableData" style="width: 100%;" @selection-change="handleSelectionChange">
                    <el-table-column prop="rfid" label="盘匣RFID号"></el-table-column>
                    <el-table-column prop="das" label="所属盘库"></el-table-column>
                    <el-table-column prop="position" label="存储位置"></el-table-column>
                    <el-table-column prop="createdAt" label="申请时间">
                        <template slot-scope="{row}">
                            {{ row.createdAt ? moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss') : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="lineStatus" label="任务类型">
                        <template slot-scope="{row}">{{ row.lineStatus === 'ONLINEING' ? '申请上线' : '申请离线' }}</template>
                    </el-table-column>
                    <el-table-column prop="actionStatus" label="任务状态">
                        <template slot-scope="{row}">{{ taskStatusList.find(i => i.value === row.actionStatus)?.label }}</template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注"></el-table-column>

                    <el-table-column align="center" prop="files" label="操作" >
                        <template slot-scope="{row}">
                            <el-button type="text" :disabled="row.actionStatus !== 'ING'" @click="handleResult(1, row)">成功</el-button>
                            <el-button type="text" :disabled="row.actionStatus !== 'ING'" @click="handleResult(2, row)">失败</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <Pager class="bs-pager" v-model="pager" :sizes="[5, 10, 20, 50, 100]"
                       @currentChange="handleCurrentChange" @sizeChange="handleSizeChange"></Pager>
            </div>
        </div>
        <FileInfo ref="fileInfoRef" />

        <el-dialog :title="'上线失败'" :visible.sync="visible" append-to-body
                   destroy-on-close
                   :close-on-click-modal="false" width="600px">
            <el-form @submit.native.prevent label-width="100px" style="">
                <el-form-item label="请填写失败原因">
                    <el-input v-model.trim="remark" type="textarea" :rows="4" placeholder="" maxlength="30"
                              show-word-limit/>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="handleCancel">取消</el-button>
                <el-button size="small" type="primary" @click="handleSubmit">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import TaskForm from "@/views/offline/components/TaskForm";
import FileInfo from "./components/FIleInfo";
import moment from 'moment'

export default {
    name: "task-list",
    components: {FileInfo},
    data() {
        return {
            loadingData: false,
            tableData: [
            ],
            pager: {
                current: 1,
                size: 10,
                total: 0
            },
            lineStatus: '',
            taskStatusList: [
                {value: 'ING', label: '进行中'},
                {value: 'SUCCESS', label: '成功'},
                {value: 'ERROR', label: '失败'},
            ],
            keyword: '',
            actionStatus: '',
            taskTypeList: [
                {label: '申请上线', value: 'ONLINEING'},
                {label: '申请离线', value: 'OFFLINEING'}
            ],
            selectedRow: [],
            visible: false,
            remark: '',
            actioningRow: {}
        }
    },
    computed: {
        ...mapGetters(['accountType'])
    },
    mounted() {
        this.loadData()
    },
    methods: {
        moment,
        goBack () {
            this.$router.go(-1)
        },
        handleResult (action, row) {
            if (action === 1) {
                this.$confirm("确认已完成操作？", {
                    confirmButtonText: "确定",
                    type: "warning",
                    showClose: false,
                    closeOnClickModal: false
                }).then(() => {
                    this.$postJson('/rf/actionStatus', {
                        "logId": row.logId,
                        "actionStatus": "SUCCESS"
                    }).then(res => {
                        if (res.code === '200') {
                            this.$message.success('提交成功')
                            this.loadData()
                        }
                    })
                })
            } else if (action === 2) {
                this.visible = true
                this.remark = ''
                this.actioningRow = row
            }
        },
        handleSelectionChange(e) {
            this.selectedRow = [...e];
        },
        loadData() {
            const {lineStatus, actionStatus} = this
            let data = {pageNum: this.pager.current, pageSize: 10}
            if (lineStatus) {
                data.lineStatus = lineStatus
            }
            if (actionStatus) {
                data.actionStatus = actionStatus
            }
            this.loadingData = true
            this.$postJson('/rf/rfAndLogPage', data)
                .then(res => {
                    if (res.code === '200') {
                        this.tableData = res.data.records
                        this.pager.total = res.data.total
                    }
                })
                .finally(() => {
                    this.loadingData = false
                })
        },
        handleSizeChange() {
            this.loadData();
        },
        handleCurrentChange(c) {
            this.pager.current = c
            this.loadData();
        },
        searchLog () {},
        taskStatusChange () {
            this.loadData()
        },
        showDetail (t) {
            // 查看日志
        },
        // 文件预览
        fileInfoPreview () {
            this.$refs.fileInfoRef.show()
        },
        handleCancel () {
            this.visible = false
        },
        handleSubmit () {
            if (!this.remark) {
                this.$message.warning('请输入失败原因')
                return
            }
            this.$confirm("确认操作失败吗？", {
                confirmButtonText: "确定",
                type: "warning",
                showClose: false,
                closeOnClickModal: false
            }).then(() => {

                const {logId} = this.actioningRow
                this.$postJson('/rf/actionStatus', {
                    logId,
                    "actionStatus": "ERROR",
                    remark: this.remark
                }).then(res => {
                    if (res.code === '200') {
                        this.$message.success('提交成功')
                        this.visible = false
                        this.loadData()
                        this.actioningRow = {}
                    }
                })
            })
        }
    }
}
</script>

<style scoped>
.offline-cassette {
    margin: 16px 16px 10px;
    padding: 0 26px;
    background: #fff;
    text-align: left;
    min-height: calc(100% - 36px);
}

.cassette-opts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0;
}
</style>